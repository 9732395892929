@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
* {
    margin:0;
    padding:0;
    box-sizing:border-box;
}

*:after, *:before {
    box-sizing: border-box;
}

html {
    font-size: 10px;
}

body {
    background:#1e1e1e;
    color:#bababa;
    font-size:1.6rem;
    font-family:'Roboto', 'Noto Sans KR', sans-serif;
}

a {
    text-decoration: none;
    color:inherit;
}

ul, li, ol {
    list-style: none;
}

button {
    font-size: inherit;
    background:none;
    border:0;
    cursor: pointer;
}

img, svg {
    max-width:100%;
    vertical-align: top;
}

label {
    cursor: pointer;
}

.hidden {
    position:absolute;
    left:-9999px;
}
